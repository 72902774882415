export default {
  lightOrange: '#FF865E',
  mainOrange: '#FB7A4F',
  darkerOrange: '#DE6C46',

  lightGrey: '#F3F7F9',
  mainGrey: '#EBF0F2',
  darkerGrey: '#DAE0E2',
  darkestGrey: '#B2C3CC',

  // lightNavy: '#475796',
  lightNavy: '#514496',
  mainNavy: '#2D3C78',
  darkerNavy: '#182142',

  lightGreen: '#00C9B6',
  mainGreen: '#00A393',
  darkerGreen: '#00786D',

  lightYellow: '#F4E961',
  // mainYellow: '#E8DC3E',
  mainYellow: '#E0EC36',
  darkerYellow: '#DFD332',

  lightRed: '#FF3E57',
  mainRed: '#E7334A',
  darkerRed: '#CB2137',

  // content width:
  laptopBreakpoint: '850px',
  contentWidthLaptop: '60rem',
  sitePadding: '25px',
  layoutColumnWidth: '22rem',

  // others
  background: '#f8f8f8',
  headerBackground: 'rgb(229, 243, 245)',
  tocBackground: 'rgb(229, 243, 245)',
};
