import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import 'prismjs/themes/prism-solarizedlight.css';
import './layout.css';
import gstackFavIcon from '../images/favicon.ico';

import TableOfContents from './toc';
import Header from './header';
import theme from '../theme';

const { sitePadding, laptopBreakpoint, contentWidthLaptop, layoutColumnWidth } = theme;

const BodyGrid = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-columns: ${layoutColumnWidth} 1fr;
  p {
    font-size: 16px;
    code {
      padding: 2px 5px !important;
      font-size: 14px;
    }
  }
  li {
    font-size: 14px;
  }

  @media screen and (max-width: ${laptopBreakpoint}) {
    display: flex;
    flex-direction: column;
    height: inherit;
  }
`;

const ToCContainer = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  z-index: 2;
  position: fixed;
  width: ${layoutColumnWidth};
  overflow-y: auto;
  height: 100%;
  @media screen and (max-width: ${laptopBreakpoint}) {
    position: initial;
    width: initial;
    overflow-y: initial;
    height: initial;
    order: 3;
  }
`;
const BodyContainer = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  width: 80%;
  padding: ${sitePadding};
  padding-left: 5rem;
  height: 100%;
  @media screen and (max-width: ${laptopBreakpoint}) {
    order: 2;
    width: 100%;
  }

  & > div {
    max-width: ${contentWidthLaptop};
    @media screen and (max-width: ${laptopBreakpoint}) {
      margin-left: initial;
    }
  }
  p,
  li {
    font-size: 16px;
    margin-bottom: 12px;
    code {
      padding: 2px 5px !important;
      font-size: 14px;
    }
    a {
      font-weight: 600;
    }
  }
  ul,
  ol {
    margin-left: 30px;
  }
  a.gatsby-resp-image-link {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const query = graphql`
  query SiteDataQuery {
    site {
      siteMetadata {
        title
      }
    }
    docsToC: allDocsJson {
      edges {
        node {
          id
          divisions {
            title
            entries {
              entry {
                childMarkdownRemark {
                  ...EntryFields
                }
              }
            }
            divisions {
              title
              entries {
                entry {
                  childMarkdownRemark {
                    ...EntryFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment EntryFields on MarkdownRemark {
    frontmatter {
      pageTitle
      title
    }
    fields {
      slug
    }
  }
`;

const Layout = ({ children, location, pageTitle = null }) => (
  <StaticQuery
    query={query}
    render={data => (
      <div>
        <Helmet
          title={pageTitle || data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'GorillaStack Documentation & User Guide' },
            {
              name: 'keywords',
              content: 'gorillastack, aws, documentation, user guide, reference, api, azure',
            },
          ]}
          link={[
            {
              rel: 'shortcut icon',
              href: `${gstackFavIcon}`,
            },
          ]}
        />
        <Header />
        <BodyGrid>
          <ToCContainer>
            <TableOfContents divisions={data.docsToC.edges[0].node.divisions} location={location} />
          </ToCContainer>
          <BodyContainer>{children}</BodyContainer>
        </BodyGrid>
      </div>
    )}
  />
);

Layout.defaultProps = {
  pageTitle: null,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.string,
};

export default Layout;
