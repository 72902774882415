/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../theme';

const TOCWrapper = styled.nav`
  padding: ${theme.sitePadding};
  padding-top: 1.5rem;
  padding-left: 40px;
  background-color: #f4f7f7;
  padding-bottom: 130px;
`;

const ChapterWrapper = styled.div`
  list-style: none;
  margin-top: 1rem;
  margin-left: 0.5rem;
`;

const ChapterTitle = styled.h3`
  text-shadow: none;
  margin-left: -0.5rem;
  margin-bottom: 10px;
  font-size: 17px;
  margin-top: 30px;
`;

const SectionWrapper = styled.div`
  padding-top: 0.5rem;
`;

const SectionTitle = styled.h4`
  text-decoration: none;
  font-size: 12px;
  color: #8093aa;
  letter-spacing: 1.6px;
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 700;
`;

const EntriesWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0.25rem;
  padding-left: 7px;
`;

const EntryWrapper = styled.li`
  font-size: 0.7rem;
  margin-bottom: 2px;
  a {
    color: #333;
    // font-weight: 600;
  }
`;

const ActiveEntryPrefix = styled.span`
  display: ${props => (props.activeLink ? 'inline-block' : 'none')};
  padding-right: 3px;
  font-weight: 900;
`;

const ExternalLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOf(PropTypes.arrayOf(PropTypes.node), PropTypes.node).isRequired,
};

const Chapter = ({ title, entries, sections, activePath }) => (
  <ChapterWrapper>
    <Link
      to={
        entries
          ? entries[0].entry.childMarkdownRemark.fields.slug
          : sections
          ? sections[0].entries[0].entry.childMarkdownRemark.fields.slug
          : null
      }
    >
      <ChapterTitle>{title}</ChapterTitle>
    </Link>
    <Entries entries={entries} activePath={activePath} />
    {sections
      ? sections.map(section => (
          <Section
            key={section.title}
            title={section.title}
            entries={section.entries}
            activePath={activePath}
          />
        ))
      : null}
  </ChapterWrapper>
);

Chapter.propTypes = {
  title: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object),
  sections: PropTypes.arrayOf(PropTypes.object),
  activePath: PropTypes.string.isRequired,
};

Chapter.defaultProps = {
  sections: undefined,
  entries: undefined,
};

const Section = ({ title, entries, activePath }) => (
  <SectionWrapper>
    <SectionTitle>{title.toUpperCase()}</SectionTitle>
    <Entries entries={entries} activePath={activePath} />
  </SectionWrapper>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  activePath: PropTypes.string.isRequired,
};

const Entries = ({ entries, activePath }) => {
  if (entries) {
    return (
      <EntriesWrapper>
        {entries.map(entry => (
          <Entry
            key={entry.entry.childMarkdownRemark.frontmatter.title}
            entry={entry.entry}
            activePath={activePath}
          />
        ))}
      </EntriesWrapper>
    );
  }

  return <div />;
};

Entries.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  activePath: PropTypes.string.isRequired,
};

const EntryLink = styled(Link)`
  font-weight: ${props => (props.activeLink ? '700' : '200')};
`;

const Entry = ({ entry, activePath }) => {
  const {
    childMarkdownRemark: {
      fields: { slug },
      frontmatter: { title },
    },
  } = entry;
  const activeLink = new RegExp(`${slug.replace(/\/$/, '')}$`).test(activePath.replace(/\/$/, ''));
  return (
    <EntryWrapper>
      <EntryLink to={slug} activeLink={activeLink}>
        <ActiveEntryPrefix activeLink={activeLink}>-</ActiveEntryPrefix>
        <span activeLink={activeLink}>{title}</span>
      </EntryLink>
    </EntryWrapper>
  );
};

Entry.propTypes = {
  entry: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  activePath: PropTypes.string.isRequired,
};

const TOC = ({ divisions, location }) => {
  const activePath = location.pathname;
  return (
    <TOCWrapper>
      {divisions.map(division => (
        <Chapter
          key={division.title}
          entries={division.entries}
          title={division.title}
          sections={division.divisions}
          activePath={activePath}
        />
      ))}
    </TOCWrapper>
  );
};

TOC.propTypes = {
  divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default TOC;
