import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoBlue from '../images/logo-blue.svg';
import slackLogo from '../images/slack.svg';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 75px;
  z-index: 2000;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderLogoWrapper = styled.div`
  display: inline-block;
  padding: 10px 0px;
  margin-left: 20px;
  width: 120px;
  height: 75px;
`;

const SlackLogoWrapper = styled.a`
  margin-left: 20px;
  position: absolute;
  right: 20px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border: 2px solid #2d3c78;
  border-radius: 3px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s;
  &:hover {
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.35);
    text-decoration: none;
  }
  img {
    margin: 0px -4px 0px -15px;
    height: 60px;
    display: inline-block;
  }
  span {
    color: #2d3c78;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1.1px;
  }
  @media screen and (max-width: 440px) {
    right: 10px;
    span {
      font-size: 11px;
    }
    img {
      height: 50px;
    }
  }
`;

const HeaderLogo = styled.img`
  display: inline-block;
  height: 55px;
`;

const ExternalLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);
ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const Header = () => (
  <HeaderWrapper>
    <HeaderLogoWrapper>
      <HeaderLogo src={logoBlue} alt="logo" />
    </HeaderLogoWrapper>
    <SlackLogoWrapper href="https://chat.gorillastack.com" target="_blank">
      <HeaderLogo src={slackLogo} alt="slack-logo" />
      <span>Join Our Slack Community</span>
    </SlackLogoWrapper>
  </HeaderWrapper>
);

export default Header;
